import React, { useState, useEffect } from "react";
import { db } from "../utils/firebase";
import HeaderPhoto from "../components/HeaderPhoto";
import Layout from "../components/Layout";
import photo from "../images/cenovnik.webp";
import { getDocs, collection, query, orderBy } from "firebase/firestore";
import * as styles from "../styles/cenovnik.module.css";

const servicesCollection = collection(db, "services");
const sortedDocs = query(servicesCollection, orderBy("createdAt", "asc"));

function Cenovnik() {
  const [products, setProducts] = useState([]);

  const fetchProducts = () => {
    getDocs(sortedDocs)
      .then((data) => {
        setProducts(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
      })
      .catch(() => console.error("No permissions!"));
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  return (
    <Layout>
      <HeaderPhoto title="Cenovnik" photo={photo} />
      <section className={styles.cenovnik}>
        <div>
          <div>
            <h2>Nutricionista - Dijetetičar</h2>
            {products
              .filter((el) => el.category === "nutricionista")
              .map((item) => (
                <div key={item.id} className={styles.item}>
                  <span>{item.name}</span>
                  <span>{item.price}</span>
                </div>
              ))}
          </div>
        </div>
        <div>
          <div>
            <h2>Masaže</h2>
            {products
              .filter((el) => el.category === "masaze")
              .map((item) => (
                <div key={item.id} className={styles.item}>
                  <div className={styles.ime}>
                    <div>{item.name}</div>
                    <div>{item.time}</div>
                  </div>
                  <div className={styles.cena}>{item.price}</div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <div>
            <h2>Tretmani ultrazvučne lipolize</h2>
            {products
              .filter((el) => el.category === "lipoliza")
              .map((item) => (
                <div key={item.id} className={styles.item}>
                  <div className={styles.ime}>
                    <div>{item.name}</div>
                    <div>{item.time}</div>
                  </div>
                  <div className={styles.cena}>{item.price}</div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <div>
            <h2>Kavitacija</h2>
            {products
              .filter((el) => el.category === "kavitacija")
              .map((item) => (
                <div key={item.id} className={styles.item}>
                  <div className={styles.ime}>
                    <div>{item.name}</div>
                    <div>{item.time}</div>
                  </div>
                  <div className={styles.cena}>{item.price}</div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <div>
            <h2>Ostalo</h2>
            {products
              .filter((el) => el.category === "ostalo")
              .map((item) => (
                <div key={item.id} className={styles.item}>
                  <span>{item.name}</span>
                  <span>{item.price}</span>
                </div>
              ))}
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Cenovnik;

export const Head = () => <title>Sonja Nikacevic | Nutricionista</title>;
